.card {

    &__container {
        &--consultation-v1 {
            min-height: 180px;
            gap: $card-consultations-v1-gap;
        }
    }

    &-vote {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 5px;
        width: 140px;
        padding: 10px;
        background-color: $color-bg--vote-v1;

        &__number {
            font-family: $font-family--heading;
            font-size: 2.500rem; // 40px
            line-height: $line-height--heading;
            font-weight: $font-weight-bold;
            color: $color--vote;
        }

        &__text {
            font-size: $font-size--text-small;
            line-height: $line-height--heading;
            color: $color--vote;
            text-transform: uppercase;
        }
    }

    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    .image-wrapper {
        &.consultations {
            background-image: url($urlSpriteImage + '/consultation-icons/consultations.svg');
        }
    }

    &--square {

        .card {
            &-vote {
                height: 70px;
                position: absolute;
                top: 0;
                row-gap: 0;
                background-color: $color-bg--vote-v2;
                // border-radius: 0 0 $border-radius $border-radius;

                &__number {
                    font-size: $font-size--2;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    
    .card {
        &__container--consultation-v1 {
            min-height: 200px;
        }
    }

}


// // 960
// @media screen and (max-width: $medium) {

// }


// 640
@media screen and (max-width: $small) {
    
    .card  {
        &__container--consultation-v1 {
            height: auto;
            flex-direction: column;
        }

        &:not(.card--square):not(.card--category):not(.card--portraits) {
            .card-vote {
                width: 100%;
            }
        }
    }

}
