// @keyframes slideDown {
//     from {
//         top: -100px;
//     }
//     to {
//         top: 0;
//     }
// }

// @keyframes slideUp {
//     from {
//         top: 0;
//     }
//     to {
//         top: -100px;
//     }
// }

.header {
    position: fixed;
    width: 100%;
    top: 0;
    margin: 0;
    z-index: 3;
    background-color: rgba($color-main, 0);
    height: 120px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;

    &__container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;

        &::before {
            content: "";
            @include absolute($bottom: 0px, $left: 0px);
            z-index: 0;
            @include size(100vw, 1px);
            background-color: rgba($color-white, 0.2);
        }
    }

    &__fp {
        &::before {
            display: none;
        }

        .tools__item {
            border-color: $color-dark;
        }
    }

    &__link {
        filter: drop-shadow(0px 0px 75px rgba(0, 0, 0, 0.5));

        .identity__title {
            margin: 0;
            font-size: 0;
            filter: drop-shadow(4px 4px 16px rgba(0, 0, 0, 0.6));
        }
    }

    &__infos {
        display: flex;
        align-items: center;
        height: fit-content;
        margin-top: 6px;
    }

    &__title {
        font-size: 0;
        line-height: 0;
    }

    &__content {
        display: flex;
        gap: 10px;
    }

    &--fixed {
        background-color: $color-dark;
        padding: 0;
        height: 80px;
        position: fixed;
        top: 0;
        z-index: 12;

        &__link {
        }

        .header {
            &__container {
                height: 100%;
                align-items: center;
                margin-top: 0;

                &.header__fp {
                    &::before {
                        display: block;
                    }
                }
            }

            &__link {
                filter: none;

                .identity__title {
                    filter: none;
                }

                svg {
                    width: 200px;
                    height: 43px;
                }
            }

            // &__content {
            //     gap: 20px;
            // }

            &__infos {
                margin-top: 0px;
            }
        }

        .tools {
            &__item {
                border: 1px solid $color-gray;
                &--search {
                    opacity: 1;
                    pointer-events: auto;
                    border: none;

                    &--disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                }

                &--translate {
                    margin-right: 10px;
                    margin-top: 5px;
                }
            }

            &__searchbar {
                width: 50px;

                .search-svg {
                    width: 49px;
                    height: 49px;
                }
                .search-container {
                    input {
                        font-size: 0;
                        height: 48px;
                        width: 48px;
                    }
                }
            }

            &__translate__wrapper {
                top: 80px;
            }
        }

        .nav-main--burger {
            .nav-main-buttons__menu:hover,
            .nav-main-buttons__menu:focus {
                background-color: $color-gray;
            }

            // .menu {
            //     margin-top: 80px;
            // }

            // &__popup {
            //     height: 450px;
            // }
        }
    }

    &.slide-up {
        animation: slideUp $duration ease-in-out forwards;
    }
}

//======================================================================================================
// Tools
//======================================================================================================

.tools {
    display: flex;
    height: fit-content;
    gap: 10px;
    justify-content: flex-end;
    position: relative;
    z-index: 0;

    &__item {
        width: 50px;
        height: 50px;
        background-color: $color-dark;
        border-color: $color-gray;
        margin: 0;
        padding: 0;
        transition: all $duration $timing;

        svg {
            fill: $color-white;
            width: 35px;
            height: 35px;
        }

        &--search {
            position: absolute;
            right: 120px;
            background-color: $color-second;
            z-index: 5;
            pointer-events: none;
            opacity: 0;
            transition: opacity $duration $timing;
            border: 0;
            transition: background-color $duration $timing;

            svg {
                fill: $color-dark;
            }
        }

        &--fb {
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #757575;
        }

        &--translate {
            margin-right: 5px;
            margin-top: 5px;
        }

        &:hover,
        &:focus {
            background-color: $color-main;
            border-color: $color-main;

            svg {
                fill: $color-white;
            }
        }
    }

    &__searchbar {
        width: 470px;
        box-sizing: content-box;
        transition: all 0.6s ease-in-out;

        .search-container {
            input {
                width: 470px;
                height: 50px;
                border-radius: 60px;
                background: rgba($color-black, 0.4);
                box-shadow: $shadow;
                padding: 0;
                margin: 0;
                border: 0;
                padding-left: 20px;
                font-family: $font-family;
                font-size: toRem(16);
                font-weight: 400;
                line-height: toRem(22);
                position: relative;
                z-index: 2;
                transition: all 0.6s ease-in-out;
                color: $color-white;
                border: 1px solid $color-gray;

                &::placeholder {
                    font-family: $font-family--heading;
                    font-size: toRem(16);
                    font-weight: 400;
                    line-height: toRem(20);
                    color: $color-white;
                    opacity: 1;
                }

                &::-webkit-search-cancel-button {
                    display: none;
                }

                &:focus {
                    background-color: rgba($color-dark, 1);
                    border: 1px solid $color-gray;
                }
            }

            .search-svg {
                background-color: $color-second;
                z-index: 3;
                width: 50px;
                height: 50px;
                transition: background-color $duration $timing;

                svg {
                    fill: $color-dark;
                    width: 70%;
                    height: 70%;
                    transition: fill $duration $timing;
                }

                &:hover,
                &:focus {
                    background-color: $color-main;

                    svg {
                        fill: $color-white;
                    }
                }
            }

            .search-close-svg {
                display: none;
                position: absolute;
                width: 20px;
                height: 20px;
                right: 10px;
                z-index: 2;
            }

            &-open {
                input {
                    background-color: $color-dark;
                }
            }

            &.focus {
                .search-svg {
                    display: none;
                }

                .search-close-svg {
                    display: flex;
                }
            }
        }

        .searchResult li {
            a {
                max-width: 440px;
                width: unset;
            }

            a,
            .resultCPT {
                font-family: $font-family--heading;
                font-size: toRem(14);
                font-weight: 400;
                line-height: toRem(20);
                color: $color-white;
            }
        }
    }

    &__translate {
        position: relative;

        &__wrapper {
            @include absolute($left: 50%, $top: 65px);
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            gap: 5px;
            width: max-content;
            padding: 15px 20px 30px;
            background-color: $color-dark;
            border-radius: $border-radius;
            transform: translateX(-50%);

            &.js-open {
                display: flex;
            }

            &::before {
                content: "";
                @include absolute($top: -6px, $left: 50%);
                display: block;
                @include size(30px);
                background-color: $color-dark;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }

            a.glink {
                position: relative;
                font-family: $font-family--heading;
                font-size: toRem(16);
                font-weight: 400;
                line-height: toRem(20);
                color: $color-white;

                &:hover,
                &:focus {
                    color: $color-second;
                    text-decoration: underline;
                }

                &.gt-current-lang {
                    color: $color-second;
                    text-decoration: underline;
                }
            }
        }

        &__close {
            @include absolute($bottom: -10px, $left: 50%);
            @include flex($alignItems: center, $justifyContent: center);
            transform: translateX(-50%);
            padding: 0;
            margin: 0;
            background-color: $color-main;
            border-radius: $border-radius--round;

            svg {
                @include size(26px);
                fill: $color-white;
            }

            &:hover,
            &:focus {
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-main;
                }
            }
        }

        a.glink.gt-current-lang {
            font-weight: normal;
        }
    }

    #accessconfig,
    .tools__translate {
        height: 40px;
    }

    &-view {
        position: absolute;
        top: 165px;
        width: 100%;
        z-index: 1;

        &__container {
            display: flex;
            justify-content: flex-end;

            #google_translate_element {
                display: none;
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .tools__searchbar {
        width: 410px;
        .search-container {
            input {
                width: 410px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    body:not(.home) {
        .header {
            background-color: $color-dark;
        }
    }

    .header {
        height: 85px;
        background-color: transparent;
        position: relative;
        z-index: 15;

        &__content {
            width: 100vw;
            position: fixed;
            bottom: 0;
            left: 0;
            align-items: center;
            background-color: $color-dark;
            justify-content: flex-end;
            height: 70px;
            margin: 0 auto;
            margin-top: 0px;
            padding: 0 10px;
            box-shadow: 0px -1px 0px 0px #ffffff33;
            padding: 0 6%;
            z-index: 1;
        }

        &__container {
            justify-content: center;
            margin-top: 30px;

            &::before {
                height: 0;
            }
        }

        &__fp {
            &::before {
                display: none;
            }

            .tools__item {
                border-color: $color-gray;
            }
        }

        .tools__searchbar {
            width: 50px;

            .search-container {
                input {
                    width: 50px;
                    font-size: 0;
                }
            }
        }

        .tools__item--search {
            opacity: 1;
            pointer-events: all;
            top: 1px;
        }

        .search-popup__content {
            opacity: 1;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .header {
        &__content {
            height: 60px;
        }

        .tools--burger {
            height: 40px;
        }

        .tools__searchbar,
        .search-svg,
        .searchInput,
        .tools__item {
            width: 40px;
            height: 40px;
        }

        .tools {
            &__searchbar {
                .search-container {
                    input {
                        width: 39px;
                        height: 39px;
                    }

                    .search-svg {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }

    .tools__item--search {
        right: 100px;
    }
}
