.consultation-survey {
    @include flex($direction: column);

    &__container {
        @include flex($direction: column, $alignItems: flex-start);
        border: 0;
        border-radius: 0;
    }

    &__title {
        font-weight: $font-weight-bold;
        padding: 0;

        span {
            color: $color-flexible-alert;
        }
    }

    &__text {
        font-style: italic;
        color: $color-gray;
    }

    &__items {
        @include flex($direction: column);
        gap: 15px;
        width: 100%;
        margin-top: 15px;
    }

    &__item {
        position: relative;
        @include flex($alignItems: center);

        label {
            @include flex($alignItems: center);
            column-gap: 15px;
            width: 100%;
            min-height: 70px;
            margin: 0;
            padding: 22px 55px 22px 20px;
            background-color: $color-bg--neutral;
            border: 1px $border-style $color-bg--transparent;
            border-radius: 10px;
            color: $color-text;
            font-weight: $font-weight-normal;
            cursor: pointer;
            transition: {
                property: border-color, opacity;
                duration: $duration;
                timing-function: $timing;
            }
        }

        .consultation_checkbox {
            flex: none;
            @include absolute($right: 20px);
            margin-left: auto;

            &:disabled ~ label {
                opacity: .7;
                cursor: auto;
            }

            &:checked {
                & ~ label {
                    border-color: $color-main;
                }
            }
        }
    }

    .scaling {
        flex: none;
        padding: 10px 15px;
        background-color: $color-bg--transparent;
        border-radius: $border-radius;
        border: 1px $border-style $color-gray;
        color: $color-gray;
        @include font-size(12);
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        transition: {
            property: background-color, border-color, color;
            duration: $duration;
            timing-function: $timing;
        }

        &.selected {
            background-color: $color-main;
            border-color: $color-main;
            color: $color-white;
        }
    }

    &__button {
        margin: 0 0 0 auto;
    }
}

// Form survey
#survey_consultations {
    .content-infos .acf-fields:first-of-type {
        .acf-field:first-child {
            margin-top: 0;
        }
    }

    .acf-field {
        .address, .zipCode, .city {
            label {
                margin-top: 0;
            }
        }
        
        .acf-date-picker {
            position: relative;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .consultation-survey {
        &__item--scaling {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 0;

            .scaling {
                @include absolute($top: 20px, $left: 20px);
            }

            label {
                padding: 77px 20px 20px 20px;
            }

            .consultation_checkbox {
                top: 20px;
            }
        }
    }

}
