.flexible {
    margin-top: 30px;
    // z-index: 15;
    // position: relative;
}

//======================================================================================================
// Bloc Document
//======================================================================================================
.bloc-document {
    margin-top: 30px;
    // z-index: 15;
    // position: relative;
}

//======================================================================================================
// Bloc Contact
//======================================================================================================
.bloc-contact {
    margin-top: 30px;
    // z-index: 15;
    // position: relative;
}

//======================================================================================================
// Bloc Video
//======================================================================================================
.bloc-video {
    // z-index: 15;
    // position: relative;
    &__wrapper {
        margin-top: 30px;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .grid-center {
        width: calc(100% + 20px);
    }
}
