.front-page {
    position: relative;
    overflow: hidden;
    z-index: 0;
    background-color: $color-white;

    h2 {
        // text-transform: uppercase;
    }
}

.container--home {
    @include flex($wrap: wrap);
    width: $large;
}

.front-page__title {
    font-family: $font-family;
    font-size: toRem(70);
    font-weight: font-weight-bold;
    line-height: toRem(52);
    color: $color-main;
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;

    span {
        font-size: toRem(50);
        font-weight: 300;
        color: $color-dark;
        margin-left: 30px;
        text-transform: none;
    }
}

.front-page {
    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .home-slideshow {
        position: relative;
        clip-path: ellipse(135% 100% at top);
        height: 698px;

        > .container {
            position: relative;
        }

        .container {
            &--content {
                position: relative;
                height: 100%;
            }

            &--navigation,
            &--pagination {
                left: 0;
                bottom: 0;
                right: 0;
                height: 0;
                height: 100%;
                pointer-events: none;
            }
        }

        .slideshow {
            @include size(100%, 100%);
            position: relative;

            &::before {
                content: "";
                @include absolute($top: 0, $left: 0);
                z-index: 0;
                @include size(100%, 100%);
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 55%);
                z-index: 2;
            }

            img,
            video {
                position: absolute;
                object-fit: cover;
                object-position: center;
                @include size(100%);
            }

            &__container {
                display: inherit;
                position: relative;
                @include size(100%);
            }

            &__content {
                @include flex($direction: column);
                box-sizing: border-box;
                @include absolute($right: 30px, $bottom: 50px);
                @include size(500px, auto);
                padding: 20px;
                background-color: $color-bg-caption;
                color: $color-caption;
            }

            &__title {
                position: absolute;
                top: 113px;
                left: 0;
                z-index: 5;
                color: $color-white;
                bottom: 0;
                right: 0;
                margin: auto auto;
                width: fit-content;
                height: fit-content;
                text-align: center;
                font-family: $font-family;
                font-size: toRem(40);
                font-weight: $font-weight-black;
                line-height: toRem(45);
                text-transform: uppercase;
                text-shadow: 4px 4px 16px #000000;
                border: 0;

                span {
                    font-size: toRem(70);
                    line-height: toRem(67);
                    text-transform: none;
                    display: block;
                }
            }

            &--video {
                img {
                    display: none;
                }
            }

            .c-pagination {
                @include absolute($bottom: 16px, $left: 50%);
                transform: translateX(-50%);
                @include flex($alignItems: center, $justifyContent: flex-end);
                gap: 10px;
                height: 25px;
                padding: 6px 15px;
                background-color: rgba($color-dark, 0.4);
                border-radius: $btn-spe-border-radius;
                z-index: 2;
                pointer-events: auto;
                backdrop-filter: blur(3px);

                .nav-swiper-autoplay {
                    @include size(12px);
                    padding: 0;
                    background-color: transparent;
                    border: 0;
                    border-radius: 0;
                    margin: 0;

                    &__icon {
                        @include size(12px);
                        fill: $color-white;
                    }

                    &:hover,
                    &:focus {
                        .nav-swiper-autoplay__icon {
                            fill: $color-white;
                        }
                    }
                }

                &__bullets {
                    @include flex($alignItems: center);
                    gap: 10px;

                    .swiper-pagination-bullet {
                        @include size(12px);
                        margin: 0 !important;
                        opacity: 1 !important;
                        background-color: $color-white;
                        border: 1px solid $color-white;
                        border-radius: $border-radius--round;
                        transition: {
                            property: background-color, border-color;
                            duration: $duration;
                            timing-function: $timing;
                        }

                        &:hover,
                        &:focus,
                        &-active {
                            background-color: transparent;
                            border-color: $color-white;
                        }
                    }
                }
            }

            .hidden {
                display: none;
            }
        }
    }

    //======================================================================================================
    // News
    //======================================================================================================

    .news {
        margin-top: 67px;
        position: relative;

        &::before {
            content: "";
            @include absolute($top: 300px, $left: 0px);
            z-index: -1;
            background: $color-dark;
            width: 100vw;
            height: 735px;
        }

        &__group {
            margin-top: 35px;
        }

        &__item {
            width: 370px;
            height: auto;
            position: relative;

            &--big {
                display: flex;
                align-items: center;
                width: 100%;

                &::before {
                    content: "";
                    @include absolute($top: 0px, $right: -33px);
                    z-index: 0;
                    @include size(100px);
                    border-radius: 50px;
                    background-image: url($urlImage + "/motif.png");
                    background-repeat: repeat;
                    background-size: 8%;
                }

                &::after {
                    content: "";
                    @include absolute($top: -7px, $right: 69px);
                    z-index: 0;
                    @include size(40px);
                    border-radius: 50px;
                    background-image: url($urlImage + "/motif.png");
                    background-repeat: repeat;
                    background-size: 20%;
                }
            }

            &:hover,
            &:focus {
                color: $color-text;

                .news__image {
                    img {
                        transform: scale(1.05);
                    }
                }

                .news__content {
                    &::before {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        &__image {
            border-radius: 10px;
            overflow: hidden;
            width: 370px;
            height: 190px;

            &--big {
                width: fit-content;
                width: 700px;
                height: 360px;
            }

            &--notimg {
                background: url($urlSpriteImage + "/base-icons/posts-icons/post.svg") no-repeat #565c61;
                background-position: 50%;
                z-index: 15;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                transition: transform $duration $timing;
                object-position: top;
            }
        }

        &__content {
            &::before {
                content: "";
                @include absolute($top: 175px, $right: 10px);
                z-index: 0;
                @include size(30px);
                border-radius: 50px;
                background: $color-second;
                background-image: url($urlSpriteImage + "/base-icons/ic-misc-plus.svg");
                background-repeat: no-repeat;
                background-size: 85%;
                background-position: center;
                transition: transform $duration $timing;
            }

            &--big {
                width: 534px;
                margin-left: -64px;
                background: $color-white;
                box-shadow: 1px 1px 20px 0px #2828281a;
                border-radius: 20px;
                padding: 35px 45px 40px 45px;
                position: relative;

                &::before {
                    @include absolute($bottom: 10px, $right: 10px, $top: unset);
                }
            }
        }

        &__category {
            padding: 5px 15px;
            background: $color-second;
            border-radius: 50px;
            width: fit-content;
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: $font-weight-semibold;
            line-height: toRem(20);
            text-transform: uppercase;
            position: absolute;
            top: 10px;
            left: 10px;

            &--big {
                position: static;
            }
        }

        &__content-title {
            font-family: $font-family;
            font-size: toRem(22);
            font-weight: $font-weight-bold;
            line-height: toRem(24);
            color: $color-white;
            margin-bottom: 0;
            margin-top: 15px;
            max-width: 330px;

            &--big {
                font-size: toRem(34);
                line-height: toRem(34);
                margin-top: 15px;
                margin-bottom: 0;
                color: $color-dark;
                max-width: 420px;
            }
        }

        &__introduction {
            font-family: $font-family;
            font-size: toRem(16);
            font-weight: 400;
            line-height: toRem(24);
            margin-top: 10px;
        }

        &__list-item {
            display: flex;
            gap: 30px;
            margin-top: 45px;
        }

        &__link {
            padding: 12px 20px;
            border-radius: 50px;
            background: $color-main;
            text-transform: uppercase;
            color: $color-white;
            display: flex;
            align-items: center;
            gap: 5px;
            width: 243px;
            margin-top: 44px;
            font-family: $font-family;
            font-size: toRem(16);
            font-weight: $font-weight-semibold;
            line-height: toRem(22.78);
            transition: {
                property: background-color, border-color, color, opacity, fill;
                duration: $duration;
                timing-function: $timing;
            }

            svg {
                @include size(26px, 26px);
                fill: $color-white;
                transition: fill $duration $timing;
            }

            &:hover,
            &:focus {
                background: $color-second;
                color: $color-dark;

                svg {
                    fill: $color-dark;
                }
            }
        }
    }

    //======================================================================================================
    // Events
    //======================================================================================================

    .events {
        position: relative;
        margin-top: 205px;

        &::before {
            background-color: #fff;
            content: "";
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: -1;
            clip-path: ellipse(136% 100% at bottom);
            top: -107px;
        }

        &__container {
            margin-top: 64px;
            position: relative;

            &::before {
                content: "";
                @include absolute($top: -5px, $right: 82px);
                z-index: 0;
                @include size(100px);
                border-radius: 50px;
                background-image: url($urlImage + "/motif.png");
                background-repeat: repeat;
                background-size: 8%;
            }

            &::after {
                content: "";
                @include absolute($top: 82px, $right: 172px);
                z-index: 0;
                @include size(40px);
                border-radius: 50px;
                background-image: url($urlImage + "/motif.png");
                background-repeat: repeat;
                background-size: 20%;
            }
        }

        &__head {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 30px;
        }

        &__navigation--prev,
        &__navigation--next {
            position: static;
            width: 50px;
            height: 50px;
            background-color: $color-main;
            border-radius: 50px;
            margin-top: 0;
            transition: all $duration ease-in-out;
            padding: 0;
            border: none;
            margin: 0;

            &:hover,
            &:focus {
                background-color: $color-second;

                svg {
                    fill: $color-dark;
                }
            }

            svg {
                width: 30px;
                height: 30px;
                fill: $color-white;
            }

            &::after {
                display: none;
            }
        }

        &__navigation {
            display: flex;
            gap: 10px;
            margin-bottom: 5px;
        }

        &__link:hover {
            color: $color-dark;

            .events__content {
                .events__date {
                    background-color: rgba($color-main, 1);
                }
            }
        }

        &__swiper.swiper-container {
            overflow: visible;
        }

        &__content-container {
            margin-top: 40px;
            position: relative;
        }

        &__item {
            width: 370px;
            height: auto;
            position: relative;
            transition: opacity $duration $timing;

            &:hover,
            &:focus {
                .events__link {
                    .events__image {
                        img {
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }

        &__image {
            border-radius: 10px;
            overflow: hidden;
            width: 100%;
            height: 340px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform $duration $timing;
            }

            &--notimg {
                background: url($urlSpriteImage + "/events-icons/events.svg") no-repeat #565c61;
                background-position: 50%;
                z-index: 15;
            }
        }

        &__date {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 100px;
            background-color: rgba($color-main, 0.8);
            display: flex;
            align-items: center;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            width: fit-content;
            transition: background-color $duration $timing;

            p {
                font-family: $font-family;
                font-size: toRem(15);
                font-weight: $font-weight-semibold;
                line-height: toRem(24);
                width: 100px;
                color: $color-white;
                text-align: center;
                text-transform: uppercase;
                display: flex;
                flex-direction: column;
                align-items: center;

                span {
                    font-family: $font-family;
                    font-size: toRem(34);
                    font-weight: $font-weight-bold;
                    line-height: toRem(34);
                    height: 40px;
                }

                &.events__date--range:nth-child(1) {
                    margin-right: -13px;
                }

                &:nth-child(2) {
                    margin-left: -13pxpx;
                }
            }

            svg {
                fill: $color-white;
                width: 26px;
                height: 26px;
            }
        }

        &__title {
            span {
                margin-left: 20px;
            }
            &--small {
                font-family: $font-family;
                font-size: toRem(28);
                font-weight: $font-weight-bold;
                line-height: toRem(30);
                margin-bottom: 0;
                margin-top: 15px;
            }
        }

        &__buttons {
            margin-top: 40px;
            display: flex;
            gap: 20px;
        }

        &__button {
            padding: 10px 0;
            border-radius: 50px;
            font-family: $font-family;
            font-size: 1rem;
            font-weight: $font-weight-semibold;
            line-height: toRem(22.78);
            text-align: center;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            gap: 5px;
            justify-content: center;

            &:nth-child(1) {
                width: 234px;
                background: $color-main;
                color: $color-white;
                transition: {
                    property: background-color, border-color, color, opacity;
                    duration: $duration;
                    timing-function: $timing;
                }

                svg {
                    fill: $color-white;
                    width: 26px;
                    height: 26px;
                    transition: fill $duration $timing;
                }

                &:hover,
                &:focus {
                    background: $color-second;
                    color: $color-dark;

                    svg {
                        fill: $color-dark;
                    }
                }
            }

            &:nth-child(2) {
                width: 300px;
                background: transparent;
                color: $color-dark;
                border: 2px solid $color-main;
                transition: {
                    property: background-color, border-color, color, opacity;
                    duration: $duration;
                    timing-function: $timing;
                }

                svg {
                    fill: $color-dark;
                    width: 26px;
                    height: 26px;
                    transition: fill $duration $timing;
                }

                &:hover,
                &:focus {
                    border-color: $color-dark;
                }
            }
        }
    }

    //======================================================================================================
    // Social - Kiosque
    //======================================================================================================
    .social-kiosque-container {
        display: flex;
        justify-content: space-between;
        margin-top: -81px;
        position: relative;
    }

    .social-kiosque {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 261px;
            left: 0px;
            width: 100vw;
            height: 450px;
            background-color: $color-dark;
            z-index: -2;
        }

        &-bg {
            width: 100vw !important;
            position: relative;
            height: 200px;
            overflow: hidden;
            background: white;
            clip-path: ellipse(76% 100% at top);
            top: 129px;
            z-index: -2;

            .container {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    top: -7px;
                    right: -135px;
                    z-index: -1;
                    width: 397px;
                    height: 385px;
                    background-image: url($urlSpriteImage + "/home-icons/oiseau.svg");
                    mix-blend-mode: exclusion;
                }
            }
        }
    }

    //======================================================================================================
    // Social
    //======================================================================================================

    .social {
        width: calc(50% - 85px);
        background: $color-white;
        box-shadow: 1px 1px 20px 0px #2828281a;
        border-radius: 20px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: -45px;
            left: -48px;
            z-index: -2;
            @include size(100px);
            border-radius: 50px;
            background-image: url($urlImage + "/motif.png");
            background-repeat: repeat;
            background-size: 8%;
        }

        &::after {
            content: "";
            z-index: -2;
            position: absolute;
            top: -51px;
            left: 61px;
            @include size(40px);
            border-radius: 50px;
            background-image: url($urlImage + "/motif.png");
            background-repeat: repeat;
            background-size: 20%;
        }

        &__container {
            padding: 65px 60px;
            display: flex;
            flex-direction: column;
            align-items: center;

            &::before {
                content: "";
                position: absolute;
                top: 80px;
                left: 34px;
                z-index: 0;
                width: 94px;
                height: 62px;
                background-image: url($urlSpriteImage + "/theme-icons/newsletter.svg");
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        &__title {
            text-align: center;
            // Todo regler soucis de line height avec pixel perfect
            line-height: toRem(60);

            span {
                margin-left: 0;
                line-height: toRem(40);
                font-size: toRem(40);
            }
        }

        &__text {
            margin-top: 25px;
            font-family: $font-family;
            font-size: 1rem;
            font-weight: 400;
            line-height: toRem(22);
            text-align: center;
        }

        &__buttons {
            margin-top: 31px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
        }

        &__btn {
            width: 300px;
            height: 50px;
            font-family: $font-family;
            font-size: 1rem;
            font-weight: $font-weight-semibold;
            line-height: toRem(22.78);
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            text-transform: uppercase;
            transition: all $duration $timing;

            &:hover,
            &:focus {
                background-color: $color-white;
                color: $color-dark;

                &.social__btn--newsletter {
                    svg {
                        fill: $color-dark;
                    }
                }
            }

            &--newsletter {
                background-color: $color-main;
                color: $color-white;
                border: 2px solid $color-main;

                svg {
                    width: 26px;
                    height: 26px;
                    fill: $color-white;
                    transition: fill $duration $timing;
                }
            }

            &--facebook {
                background-color: $color-second;
                color: $color-dark;
                border: 2px solid $color-second;

                svg {
                    width: 26px;
                    height: 26px;
                    border-radius: 50px;
                    border: 1px solid$color-dark;
                    margin-right: 5px;
                }
            }
        }
    }

    //======================================================================================================
    // Kiosque
    //======================================================================================================

    .kiosque {
        width: calc(50% + 15px);
        position: relative;

        &__container {
            padding: 0;
            margin: 0;
        }

        &__title {
            span {
                margin-left: 60px;
            }
        }

        &__list-container {
            overflow: visible !important;
            margin-top: 43px;
            position: relative;
            margin-left: 0 !important;
            max-width: 100%;
        }

        &__list {
            overflow: visible;
        }

        &__item {
            display: flex;
            justify-content: flex-start;
            overflow: visible;
        }

        &__image {
            max-height: 385px;
            max-width: 270px;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 1px 1px 20px 0px #2828281a;
            @include size(270px, 385px);

            &--notimg {
                background: url($urlSpriteImage + "/base-icons/documents-icons/documents.svg") no-repeat #565c61;
                background-position: 50%;

                @include flex($justifyContent: center, $alignItems: center);

                svg {
                    object-fit: cover;
                    width: 50%;
                }
            }

            img {
                height: 100%;
            }
        }

        &__content {
            // width: 305px;
            height: 130px;
            padding: 0 30px;
            @include flex($direction: column, $alignItems: flex-start, $justifyContent: center);
            gap: 7px;
            margin-top: 104px;
        }

        &__title--small {
            font-family: $font-family;
            font-size: toRem(34);
            font-weight: font-weigt-bold;
            line-height: toRem(34);
            margin: 0;
            color: $color-white;
        }

        &__infos {
            display: flex;
            gap: 3px;

            &__item {
                max-height: 24px;
                display: flex;
                gap: 5px;
                font-family: $font-family;
                font-size: toRem(16);
                font-weight: 400;
                line-height: toRem(24);
                color: $color-white;

                svg {
                    width: 22px;
                    fill: $color-gray;
                }
            }
        }

        &__buttons {
            display: flex;
            gap: 10px;
            max-height: 50px;
            z-index: 2;
            margin-top: 10px;
        }

        &__button {
            @include size(46px, 46px);
            background-color: $color-dark;
            border: 2px solid $color-white;
            border-radius: 50px;
            @include flex($alignItems: center, $justifyContent: center);
            z-index: 2;
            transition: all $duration $timing;

            svg {
                max-width: 30px;
                max-height: 30px;
                fill: $color-white;
                transition: fill $duration $timing;
            }

            &:hover,
            &:focus {
                background-color: $color-second;
                border-color: $color-second;
                svg {
                    fill: $color-dark;
                }
            }
        }

        &__navigation {
            @include flex($direction: row, $alignItems: center);
            @include size(110px, 50px);
            gap: 10px;
            position: absolute;
            top: 170px;
            right: 190px;

            .swiper-button {
                position: unset;
                @include size(50px);
                border-radius: $border-radius--round;
                background-color: $color-main;
                border: 0;
                margin: 0;
                padding: 0;
                transition: background-color $duration $timing;

                &-disabled {
                    pointer-events: auto;
                }

                &:before,
                &:after {
                    display: none;
                }

                svg {
                    @include size(30px);
                    fill: $color-white;
                    transition: fill $duration $timing;
                }

                &:hover,
                &:focus {
                    background-color: $color-second;

                    svg {
                        fill: $color-dark;
                    }
                }
            }
        }

        &__swiper {
            display: flex;
            flex-direction: column;
            position: relative;
        }

        &__allbutton {
            height: 45px;
            border: none;
            width: 229px;
            padding: 0;
            color: $color-dark;
            margin-right: 7px;
            border: 1px solid rgba($color-main, 0);
            transition: all 0.25s ease-in-out;

            &:hover,
            &:focus {
                color: $color-dark;
                background-color: transparent;
                border: 1px solid rgba($color-dark, 1);
            }
        }

        &__buttons-link {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: fit-content;
            position: absolute;
            bottom: 0;
            right: 50px;
            z-index: 2;
        }

        &__link {
            width: 250px;
            border-radius: 50px;
            font-family: $font-family;
            font-size: 1rem;
            font-weight: $font-weight-semibold;
            line-height: torem(22.78);
            color: $color-white;
            padding: 12px 0px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            transition: all $duration $timing;

            svg {
                width: 26px;
                height: 26px;
                fill: $color-white;
                transition: all $duration $timing;
            }

            &--all-doc {
                background-color: $color-main;

                &:hover,
                &:focus {
                    background: $color-second;
                    color: $color-dark;

                    svg {
                        fill: $color-dark;
                    }
                }
            }

            &--legal {
                background-color: $color-dark;
                border: 2px solid $color-main;
                padding: 10px 0;

                &:hover,
                &:focus {
                    border-color: $color-second;
                }
            }
        }
    }
}
//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .front-page {
        //======================================================================================================
        // home-slideshow
        //======================================================================================================

        .home-slideshow {
            height: 557px;
        }
        //======================================================================================================
        // News
        //======================================================================================================

        .news {
            margin-top: 63px;
            &__list-item {
                gap: 20px;
            }

            &__item {
                &--big {
                    &::before {
                        top: -33px;
                        right: -28px;
                    }

                    &::after {
                        top: -40px;
                        right: 74px;
                    }
                }
            }

            &__image {
                width: 300px;
                height: 155px;

                &--big {
                    width: 560px;
                    height: 300px;
                }
            }

            &__content {
                &::before {
                    top: 140px;
                }

                &--big {
                    padding: 35px 30px 40px 30px;
                    margin-left: -40px;
                    width: 420px;

                    &::before {
                        top: unset;
                    }
                }
            }

            &__content-title {
                max-width: 100%;
            }

            &__link {
                margin-top: 53px;
            }
        }

        //======================================================================================================
        // Events
        //======================================================================================================

        .events {
            &::before {
                top: -99px;
                clip-path: ellipse(142% 100% at bottom);
            }

            &__container {
                margin-top: -13px;
            }

            &__item {
                width: 300px;
            }

            &__image {
                height: 275px;
            }

            &__buttons {
                margin-top: 54px;
            }
        }

        //======================================================================================================
        // Social
        //======================================================================================================

        .social {
            width: calc(50% - 70px);

            &__container {
                padding: 65px 40px;

                &::before {
                    width: 67px;
                    height: 44.1px;
                    top: 97px;
                    left: 22px;
                }
            }

            &__title {
                span {
                    font-size: toRem(40);
                }
            }

            &__text {
                margin-top: 15px;
            }

            &__buttons {
                margin-top: 19px;
            }
        }

        //======================================================================================================
        // Kiosque
        //======================================================================================================

        .kiosque {
            width: calc(50% + 10px);

            &__image {
                width: 210px;
                height: 300px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &__navigation {
                right: 140px;
            }

            &__buttons-link {
                position: static;
                flex-direction: row;
                margin-top: 35px;
                gap: 25px;
            }

            &__link {
                &--all-doc {
                    width: 230px;
                }

                &--legal {
                    width: 195px;
                }
            }

            &__content {
                padding-left: 20px;
                max-height: calc(100px + 15px + 50px);
                max-width: 250px;
                margin-top: 120px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .front-page__title {
        font-size: toRem(50);

        span {
            font-size: toRem(30);
            height: 35px;
            margin-left: 22px;
            margin-bottom: 5px;
        }
    }

    .front-page {
        margin-top: -85px;
        .home-slideshow {
            height: 390px;

            .slideshow {
                &__title {
                    font-size: toRem(30);
                    line-height: toRem(35);
                    top: 80px;
                    top: 91px;

                    span {
                        font-size: toRem(50);
                        line-height: toRem(45);
                    }
                }
            }
        }

        //======================================================================================================
        // News
        //======================================================================================================

        .news {
            margin-top: 81px;

            &::before {
                height: 1185px;
            }

            &__group {
                margin-top: 27px;
            }

            &__list-item {
                flex-wrap: wrap;
                gap: 20px 0;
                justify-content: space-between;
                margin-top: 19px;
            }

            &__item {
                width: calc(50% - 10px);

                &--big {
                    width: 100%;
                    flex-direction: column;

                    &::before {
                        right: -38px;
                        z-index: -1;
                    }

                    &::after {
                        right: 64px;
                    }
                }
            }

            &__content {
                &::before {
                    top: 160px;
                    right: 15px;
                }

                &--big {
                    margin-left: 0;
                    margin-top: -35px;
                    width: calc(100% - (2 * 25px));

                    &::before {
                        bottom: 10px;
                        top: unset;
                        right: 10px;
                    }
                }
            }

            &__image {
                width: 100%;
                height: 175px;

                &--big {
                    width: 100%;
                    height: 375px;
                }
            }

            &__link {
                margin-top: 42px;
            }
        }

        //======================================================================================================
        // Events
        //======================================================================================================

        .events {
            margin-top: 198px;

            &::before {
                clip-path: ellipse(180% 100% at bottom);
            }

            &__navigation {
                margin-bottom: 21px;
            }

            &__content-container {
                margin-top: -6px;
            }

            &__title {
                &--small {
                    margin-top: 10px;
                }
            }

            &__buttons {
                margin-top: 45px;
            }

            &__item {
                width: 340px;
            }

            &__image {
                height: 310px;
            }
        }

        //======================================================================================================
        // Social + kiosque
        //======================================================================================================

        .social-kiosque-container {
            flex-direction: column;
            gap: 60px;
        }

        .social-kiosque::before {
            height: 800px;
            top: 247px;
        }

        .social-kiosque-bg {
            top: 70px;
            clip-path: ellipse(109% 100% at top);
        }

        //======================================================================================================
        // Social
        //======================================================================================================

        .social {
            width: 100%;
            background: transparent;
            box-shadow: none;
            margin-top: 84px;

            &__container {
                padding: 28px 60px 51px;
                margin-top: -118px;
                background: #fff;
                border-radius: 20px;
                box-shadow: 1px 1px 20px 0 rgba(40, 40, 40, 0.102);

                &::before {
                    display: none;
                }
            }

            &__text {
                margin-top: 20px;
            }

            &__title {
                line-height: toRem(40);

                span {
                    font-size: toRem(30);
                    line-height: unset;
                }

                br {
                    display: none;
                }
            }

            &__buttons {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
            }

            &__btn {
                width: 280px;
            }
        }

        //======================================================================================================
        // Kiosque
        //======================================================================================================

        .kiosque {
            width: unset;
            margin-top: -8px;

            &__container {
                max-width: 100%;
            }

            &__title {
                span {
                    color: $color-white;
                    margin-left: 45px;
                    margin-bottom: 2px;
                }
            }

            &__list-container {
                margin-top: 34px;
            }

            &__content {
                max-width: unset;
                margin-top: 126px;
            }

            &__buttons {
                margin-top: 35px;
            }

            &__buttons-link {
                gap: 25px;
            }

            &__navigation {
                inset: 148px 0 0 230px;
            }
        }
    }

    .news {
        &__content-title {
            max-width: 300px;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .front-page {
        .home-slideshow {
            height: 300px;

            .slideshow {
                &__title {
                    display: none;
                }
            }
        }

        //======================================================================================================
        // News
        //======================================================================================================

        .news {
            margin-top: 31px;
            &__image {
                height: 155px;

                &--big {
                    height: 170px;
                }
            }

            &__content {
                &::before {
                    top: 140px;
                    right: 5px;
                }

                &--big {
                    width: 100%;
                    margin-top: -20px;
                    padding: 35px 20px 35px;

                    &::before {
                        top: unset;
                        right: 10px;
                    }
                }
            }

            &__item {
                width: 100%;

                &--big {
                    &::before {
                        right: -32px;
                    }

                    &::after {
                        right: 70px;
                    }
                }
            }

            &__list-item {
                gap: 46px;
                margin-top: 40px;
            }

            &__title {
                margin-top: 10px;
            }
        }

        //======================================================================================================
        // Events
        //======================================================================================================

        .events {
            margin-top: 114px;

            &::before {
                top: -56px;
                clip-path: ellipse(268% 100% at bottom);
            }
            &__item {
                width: 100%;
            }

            &__image {
                height: 275px;
            }
            &__buttons {
                flex-direction: column;
            }

            &__navigation {
                margin-bottom: 5px;
            }

            &__title--small {
                margin-top: 15px;
            }

            &__buttons {
                margin-top: 51px;
                z-index: 15;
                position: relative;
            }

            &__button:nth-child(1) {
                padding: 12px 0;
            }
        }

        // TOdo remove before production
        .social-kiosque {
            margin-bottom: 500px;

            &::before {
                height: 1450px;
                top: 131px;
            }

            &-bg {
                clip-path: ellipse(150% 100% at top);
                top: -39px;
                &-container {
                    &::before {
                        display: none;
                    }
                }
            }
        }

        //======================================================================================================
        // Social
        //======================================================================================================

        .social {
            margin-top: 56px;
            &__container {
                padding: 34px 43px;
            }

            &__text {
                margin-top: 14px;
            }

            &__buttons {
                margin-top: 23px;
            }

            &__text {
                margin-top: 18px;
            }
        }

        //======================================================================================================
        // Kiosque
        //======================================================================================================

        .kiosque {
            margin-top: 3px;
            &__content {
                padding-left: 0;
                margin-top: 95px;
                max-height: unset;
                height: unset;
                max-width: unset;
                width: 300px;
            }

            &__list-container {
                margin-top: 33px;
            }

            &__navigation {
                inset: 448px 0 0 0;
            }

            &__item {
                flex-direction: column;
            }

            &__buttons-link {
                flex-direction: column;
                margin-top: 25px;
                gap: 20px;
            }

            &__buttons {
                margin-top: 12px;
            }
        }
    }
}

@media screen and (max-width: 620px) {
    .front-page {
        .social {
            &__title {
                br {
                    display: block;
                }
            }
        }
    }
}

@media screen and (max-width: $x-small) {
    .front-page {
        .news {
            &__list-item {
                gap: 30px;
            }

            &__content {
                &--big {
                    padding: 35px 20px;
                }
            }

            &__content-title {
                &--big {
                    margin-top: 10px;
                }
            }

            &::before {
                height: 1307px;
                top: 200px;
            }
        }

        .social-kiosque {
            &-bg {
                top: 50px;
            }

            &::before {
                top: 233px;
            }
        }

        .social {
            &__container {
                padding: 32px 10px;
            }

            &__title {
                line-height: toRem(50);
            }

            &__text {
                padding: 0 10px;
            }

            &__buttons {
                margin-top: 19px;
            }
        }

        .kiosque {
            margin-top: -5px;
        }
    }
}
