.card {
    &__container {
        // Events v1
        &--event-v1 {
            gap: $card-event-v1-gap;
            padding: $card--padding;

            .card__content {
                padding: $card-content--padding;
            }
        }
    }

    //===========================
    // Events
    //===========================
    &__date {
        display: flex;
        align-items: stretch;
        padding: 20px 0;
        background-color: $color-bg--date-v1;

        p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100px;
            color: $color--date;
        }

        &__many {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &_day {
            font-family: $font-family--heading;
            font-weight: $font-weight--heading;
            font-size: $font-size--2;
            line-height: $line-height--heading;
        }

        &_month {
            font-size: $font-size--text-small;
            text-transform: uppercase;
            margin-top: 5px;
        }

        svg {
            box-sizing: content-box;
            width: 26px;
            height: 26px;
            fill: $color--date;
        }
    }

    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    .image-wrapper {
        &.events,
        &.events_category {
            background-image: url($urlSpriteImage + "/events-icons/events.svg");
        }
    }

    //=================================================================================================
    // Squared cards appearance
    //=================================================================================================
    &--square {
        .card {
            //===========================
            // Events
            //===========================
            &__date {
                position: absolute;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0;
                padding: 0;
                background-color: $color-bg--date-v2;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                transition:
                    background-color $duration $timing,
                    opacity $duration $timing;

                p {
                    width: 100px;
                    height: 100px;
                    color: $color--date;
                    padding: 15px 0;
                }

                svg {
                    transform: rotate(-90deg);
                    box-sizing: inherit;
                    padding: 0;
                    fill: $color--date;
                }
            }
        }

        &:hover {
            .card__date {
                background-color: rgba($color-main, 1);
            }
        }
    }
}

.c-date {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: 65px;

    p {
        width: 105px;
        font-family: $font-family;
        font-size: toRem(18);
        font-weight: $font-weight-semibold;
        line-height: toRem(24);
        text-transform: uppercase;
    }

    svg {
        transform: rotate(90deg);
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    .card {
        &:not(.card--square):not(.card--category):not(.card--portraits) {
            .card__image-wrapper:not(.events) {
                display: none;
            }

            .card__image-wrapper.events {
                background: none;
                width: auto;

                img {
                    display: none;
                }
            }

            .card__date {
                position: relative;

                p {
                    width: 85px;
                }
            }
        }

        &__container--event-v1 {
            .card__date {
                padding: 20px 5px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .card {
        &:not(.card--square):not(.card--category):not(.card--portraits) {
            .card__image-wrapper.events {
                background: none;
                width: 100%;
                height: 70px;
                margin: 0 0 20px 0;
            }

            .card__date {
                position: relative;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 0;

                &__many {
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }

                p {
                    width: auto;
                    padding: 15px;
                }

                svg {
                    transform: rotate(-90deg);
                }
            }
        }

        &__container--event-v1 {
            height: auto;
        }
    }
}
