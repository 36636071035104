//===============================================
// Actions buttons
//===============================================
.documents-details__button {
    @include flex($alignItems: center);
    column-gap: 10px;
    margin: 0;
    cursor: pointer;
    font-family: $font-family;
    font-weight: $font-weight;
    font-size: $font-size--text;
    line-height: $line-height;
    text-decoration: underline;
    text-transform: none;
    color: $color--card;

    svg {
        @include size($btn-action-size);
        background-color: transparent;
        border: $btn-action-width $btn-action-style $btn-action-border-color;
        border-radius: $btn-action-border-radius;
        fill: $btn-action-color;
        transition: {
            property: background-color, border-color, fill, text-decoration;
            duration: $duration;
            timing-function: $timing;
        }
    }

    &:hover, &:focus {
        text-decoration: none;
        color: $color-text;

        svg {
            background-color: $btn-action-bg--hover;
            border-color: $btn-action-border-color--hover;
            // fill: $color-white;
        }
    }
}