.quick-access {
    position: absolute;

    &__button {
        position: fixed;
        top: 300px;
        margin: 0;
        right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        width: 110px;
        height: 80px;
        background-color: $color-second;
        text-transform: uppercase;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: end;
        color: $color-dark;
        border: none;
        gap: 1px;
        transition: {
            property: background-color, border-color, opacity;
            duration: $duration;
            timing-function: $timing;
        }

        &:hover,
        &:focus {
            background-color: $color-main;
            color: $color-white;

            .quick-access__svgd {
                svg {
                    fill: $color-white;
                }
            }

            .quick-access__title {
                color: $color-white;
            }
        }
    }

    &__svgd {
        width: 17.5px;
        height: 17.5px;
        margin-top: -5px;
        margin-bottom: 5px;

        svg {
            fill: $color-dark;
            width: 100%;
            height: 100%;
        }
    }

    &__title {
        font-family: $font-family;
        font-size: toRem(16);
        font-weight: $font-weight-bold;
        line-height: toRem(16);
        transition: {
            property: background-color, border-color, color, opacity;
            duration: $duration;
            timing-function: $timing;
        }
    }

    &__menu {
        position: fixed;
        background-color: $color-dark;
        top: 0;
        right: -402px;
        z-index: 25;
        width: 400px;
        height: 100vh;
        padding: 40px;
        box-shadow: -1px 0px 0px 0px #ffffff33;
        transition:
            right $duration $timing,
            bottom $duration $timing;

        &.quick-menu-open {
            right: 0;
        }
    }

    &__close-container {
        display: flex;
        justify-content: flex-end;
    }

    &__close {
        margin: 0;
        border: 2px solid $color-white;
        width: 40px;
        height: 40px;
        background: transparent;
        padding: 0;

        svg {
            fill: $color-white;
            width: 25px;
            height: 25px;
        }

        &:hover {
            background: $color-white;
            border-color: $color-white;

            svg {
                fill: $color-dark;
            }
        }
    }

    &__text {
        margin-top: 48px;
        font-size: toRem(26);
        font-weight: 400;
        line-height: toRem(37.02);
        color: white;
        text-transform: uppercase;
        text-align: center;
    }

    &__list {
        margin-top: 48px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px 7px;
        justify-content: center;
    }

    &__item {
        width: 150px;
        height: 140px;

        .quick-access__link {
            svg:nth-of-type(1) {
                transition: transform $duration $timing;
            }
        }

        &:nth-child(3) {
            .quick-access__link {
                svg:nth-of-type(2) {
                    width: 26px;
                    height: 26px;
                    fill: white;
                    transition: fill $duration $timing;
                }
            }
        }

        &:nth-last-child(2),
        &:last-child {
            margin-top: 11px;
        }

        &:hover {
            .quick-access__link {
                color: $color-second;

                svg:nth-of-type(1) {
                    transform: translateY(-5px);
                }
                svg:nth-of-type(2) {
                    fill: $color-second;
                }
            }
        }
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $color-white;
        font-family: $font-family;
        font-size: toRem(18);
        font-weight: font-weight-semibold;
        line-height: toRem(20);
        text-align: center;
        transition: color $duration $timing;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .quick-access {

        &__text {
            margin-top: 5px;
        }

        &__list {
            margin-top: 18px;
        }

        &__button {
            width: 70px;
            height: 70px;
            padding: 0 7px;
            top: 200px;
        }

        &__svgd {
            svg {
                width: 17.5px;
                height: 17.5px;
            }
        }

        &__title {
            font-size: toRem(12);
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    // Class uniquement sur le body et permettant d'enlever le scroll sur la page quad les acces rapides sont ouvert
    .quick-access-open {
        overflow: hidden;
    }

    .quick-access {
        &__button {
            width: 80px;
            height: 80px;
            padding: 13px 17px;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 0;
            border-top-left-radius: 50px;
            border-bottom-left-radius: 0px;
            align-items: center;
            bottom: 0;
            top: unset;
            left: 6.6%;
            z-index: 1;
        }

        &__svgd {
            svg {
                width: 17.5px;
                height: 17.5px;
                transform: rotate(90deg);
            }
        }

        &__title {
            font-size: toRem(12);
            text-align: center;
        }

        &__menu {
            top: unset;
            right: 0px;
            bottom: -100vh;
            width: 100vw;
            height: 100dvh;

            &.quick-menu-open {
                bottom: 0;
            }
        }
    }
}

// 700
@media screen and (max-width: $small) {
    .quick-access {
        &__item {
            width: 135px;
        }

        &__text {
            margin-top: -17px;
        }

        &__list {
            margin-top: 15px;
        }

        &__button {
            width: 70px;
            height: 70px;
        }

        &__menu {
            padding: 40px 35px;
        }
    }
}
