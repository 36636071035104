.footer {
    background-color: $color-dark;
    color: $color-white;
    font-family: $font-family;
    padding-top: 80px;
    position: relative;
    padding-bottom: 30px;

    &::before {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-185px);
        z-index: 0;
        width: 1235px;
        height: 425px;
        background-image: url($urlImage + "/frise_footer.jpg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__infos {
        width: 265px;
    }

    &__title {
        font-size: toRem(28);
        font-weight: $font-weight-bold;
        line-height: toRem(25);

        span {
            font-size: toRem(24);
            display: block;
        }
    }

    &__text {
        margin: 10px 0 15px;
        font-family: Source Sans 3;
        font-size: toRem(18);
        font-weight: 400;
        line-height: toRem(24);
    }

    &__phone {
        font-family: Source Sans 3;
        font-size: 18px;
        font-weight: 700;
        line-height: toRem(25.63);
        text-align: left;
        display: flex;
        align-items: center;

        svg {
            width: 26px;
            height: 26px;
            fill: $color-white;
            transition: fill $duration $timing;
        }

        &:hover {
            color: $color-second;

            svg {
                fill: $color-second;
            }
        }
    }

    &__links {
        margin-top: 4px;
        display: flex;
        gap: 28px;
        width: 500px;
        align-items: flex-end;
        position: relative;
        z-index: 2;

        div {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

    &__link {
        width: 245px;
        height: 50px;
        border-radius: 50px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-family;
        font-size: 1rem;
        font-weight: $font-weight-semibold;
        line-height: toRem(22.78);
        gap: 5px;
        transition: {
            property: background-color, border-color, color, opacity;
            duration: $duration;
            timing-function: $timing;
        }

        &:hover {
            background: $color-main;
            border-color: $color-main;
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }

        svg {
            width: 26px;
            height: 26px;
            transition: fill $duration $timing;
        }

        &--contact {
            background-color: $color-second;
            color: $color-dark;

            svg {
                fill: $color-dark;
            }
        }

        &--schedules {
            background: transparent;
            border: 2px solid $color-second;
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }
    }

    &__plan {
        display: flex;
        align-items: center;

        & > svg:nth-child(1) {
            width: 140px;
            height: 140px;
        }

        div {
            width: 81px;
            margin-top: -10px;
            margin-left: 5px;
            gap: 0;
            svg {
                width: 20px;
                height: 20px;
                fill: $color-white;
                transition: fill $duration $timing;
            }

            p {
                font-family: $font-family;
                font-size: 1rem;
                font-weight: $font-weight-semibold;
                line-height: 1rem;
                text-transform: uppercase;
                transition: color $duration $timing;
            }
        }

        svg:nth-child(1) path:nth-of-type(3) {
            transition: fill $duration $timing;
        }

        &:hover {
            svg:nth-child(1) path:nth-of-type(3) {
                fill: $color-second;
            }
            div {
                svg {
                    fill: $color-second;
                }
                p {
                    color: $color-second;
                }
            }
        }
    }

    &__menu {
        margin-top: 40px;
        position: relative;
        z-index: 0;

        &__list {
            display: flex;
            gap: 33px;
            align-items: flex-end;
            padding-bottom: 4px;
        }

        &__item {
            text-transform: uppercase;
            font-family: $font-family;
            font-size: toRem(13);
            font-weight: 400;
            line-height: toRem(22);

            &:hover {
                .footer__menu__link {
                    color: $color-white;
                    opacity: 0.8;
                }
            }
        }

        .container {
            display: flex;
            justify-content: space-between;
        }

        &__links {
            display: flex;

            gap: 20px;
        }

        &__link {
            height: 60px;
            border-radius: 5px;
            overflow: hidden;
            transition: all $duration $timing;
        }
    }
}

body:not(.home) {
    .footer {
        padding-top: 100px;
        z-index: 0;

        &::after {
            background-color: #fff;
            top: -565px;
            clip-path: ellipse(134% 610px at top);
            content: "";
            height: 610px;
            left: 0;
            position: absolute;
            width: 100%;
            z-index: -1;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer {
        &::before {
            transform: translateX(-85px);
            width: 930px;
            height: 320px;
        }

        &__menu {
            &__list {
                flex-wrap: wrap;
                width: 450px;
                padding-bottom: 0;
                gap: 15px 30px;
            }

            .container {
                align-items: center;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        padding-bottom: 205px;

        &::before {
            left: 0;
            transform: translateX(0);
        }

        &__menu {
            .container {
                flex-direction: column;
                align-items: flex-start;
                gap: 25px;
            }

            &__list {
                width: 100%;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    body:not(.home) {
        .footer {
            margin-top: 0;
            
        }
    }
    
    .footer {
        padding-bottom: 110px;
        margin-top: -500px;
        z-index: 1;
        

        &::before {
            display: none;
        }

        &__links {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 24px;
        }

        &__menu {
            margin-top: 50px;

            .container {
                gap: 28px;
            }
        }
    }
}
