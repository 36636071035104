.wrapper {
    #comarquage {


        // Top
        #co-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            float: none;
            padding: 20px;
            background-color: $color-bg--neutral;
            border-radius: $border-radius;

            .co-home  {
                padding: 10px 0 0 10px;

                svg {
                    transition: fill $timing $duration;

                    &:hover {
                        transition: fill $timing $duration;
                    }
                }
            }

            #co-search {
                display: flex;
                align-items: center;
                width: 70%;
                margin: 0;       
                border-radius: 20px;         

                input {
                    float: none;
                    height: 60px;
                    padding: 19px 65px 19px 25px;
                    background-color: $color-white;
                    border: $form-border;
                    border-color: $search-input-border-color;
                    border-radius: $border-radius;
                    font-size: $font-size--text;

                    &:focus {
                        border-color: $search-input-border-color--active;
                    }
                }
            }

            .btn-monsp {
                float: none;
                padding: 0;
                background-color: $color-white;
                border-radius: $border-radius;
                line-height: 0;

                img {
                    height: auto;
                    padding: 12px 15px;
                }

                &:hover {
                    img {
                        transform: none;
                    }
                }
            }
        }

        h1.search-title {
            margin-bottom: 25px !important;

            b {
                font-size: $font-size--2;
            }
        }

        #co-page {
            .search-result .colorsearch {
                background-color: $color-highlight;
            }
        }


    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .wrapper #comarquage #co-bar #co-search {
        width: 60%;
    }

}


// 960
@media screen and (max-width: $medium) {

    .wrapper #comarquage #co-bar {
        justify-content: flex-start;

        #co-search {
            width: 88%;
            margin-left: 20px;
        }

        .btn-monsp {
            margin-top: 20px;
        }
    }
}


// 768
@media screen and (max-width: 768px) {

    .wrapper #comarquage #co-bar  {

        .co-home {
            padding: 0 10px 10px;
        }

        #co-search {
            width: 100%;
            margin-left: 0;
        }

        .btn-monsp {
            margin: 20px auto 0;
        }

    }

}
