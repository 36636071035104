.cover {
    position: relative;
    background-color: $color-dark;
    min-height: 410px;
    z-index: 1;
    overflow: hidden;
    height: calc(100% + 150px);

    // &::before {
    //     content: "";
    //     @include absolute($top:120px, $left: 0px);
    //     z-index: 0;
    //     @include size(100%, 1px);
    //     background-color: rgba($color-white, .2);
    //     z-index: 1;
    // }

    &::after {
        content: "";
        @include absolute($bottom: -565px, $left: 0px);
        z-index: 0;
        @include size(100%, 610px);
        background-color: white;
        clip-path: ellipse(134% 610px at bottom);
        z-index: 1;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        padding-top: 160px;
        align-items: flex-start;
        padding-bottom: 100px;
    }

    &__image-wrapper {
        width: calc(50% - 15px);
        height: 350px;
        background-color: $color-bg--image;
        border-radius: 20px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // object-position: center;
        }
    }

    &__information-group {
        background-color: $color-dark;
        width: 100%;
    }

    // permet de définir une width de 50% si il y a une image
    .cover__image-wrapper ~ .cover__information-group {
        background-color: $color-dark;
        width: calc(50% - 15px);
    }

    &__informations {
        margin-top: 8px;
        .container {
            padding: 0;
        }
    }

    &__title {
        color: $color-white;
        margin: 0px 0px -5px 0;
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-family: "Source Sans 3";
            font-size: 18px;
            font-weight: 700;
            line-height: toRem(26);
            text-align: left;
            color: $color-white;
        }
    }

    &__intro {
        color: $color-text;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        &__image-wrapper {
            height: 284px;
            width: calc(50% - 10px);
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .cover {
        padding-bottom: 75px;

        // permet de définir une width de 50% si il y a une image
        .cover__image-wrapper ~ .cover__information-group {
            background-color: $color-dark;
            width: 100%;
        }

        &__container {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding-top: 40px;
            padding-bottom: 60px;
        }

        &__image-wrapper {
            width: 100%;
            height: 285px;
            display: flex;
        }

        &__information-group {
            margin-top: 20px;
            width: 100%;
        }

        &__informations {
            .container {
                margin: 0;
                max-width: unset;
            }
        }

        &::after {
            clip-path: ellipse(165% 100% at bottom);
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }

        &__image-wrapper {
            height: 255px;
        }
    }
}
