.nav-main {
    position: relative;
    z-index: 10;
    background: $color-main;

    // Container menu
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    // Main menu
    .menu {
        display: flex;
        flex-wrap: wrap;
        transition: $duration ease-out;

        &__item {

            &.menu-item-has-children {
                &:hover, &:focus-within {
                    .submenu {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
    
            &:last-child {
               .nav-main-link {
                    &:after {
                        width: 0;
                        height: 0;
                        background: transparent;
                    }
                }
            }
        }   
        
        &__link {
            position: relative;
            display: flex;
            align-items: center;
            max-width: 160px;
            height: 80px;
            padding: 0 15px;
            color: $color-white;
            text-transform: uppercase;
    
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 100%;
                background: $color-white;
                transform: translateY(-50%);
            }
    
            &:hover, 
            &:focus {
                background: $color-bg--neutral;
                color: $color-text;
            }
        }
    }

    // Sub menu
    .submenu {
        display: none;
        position: absolute;
        right: 0;
        z-index: 1;
        width: 100%;
        padding: 30px 15px;
        background: $color-bg--neutral;
        box-shadow: $shadow;

        &__nav {
            width: 100%;
            column-count: 3;
            column-gap: 30px;
        }

        &__item {
            display: inline-flex;
            width: 100%;
            margin: -1px; // Fix space beetween inline-flex
            padding: 0;
            line-height: initial;
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 60px;
            margin-bottom: 1px; // Fix space beetween inline-flex
            padding: 15px 30px 15px 0;
            border-bottom: 1px solid $color-second--rgba;
            color: $color-text;
            font-weight: $font-weight-medium;

            &::before {
                content: "";
                display: inherit;
                position: absolute;
                bottom: 0;
                width: 0;
                height: 1px;
                background: $color-text;
                transition: all $duration;
            }

            &::after {
                content: '\f345';
                font: normal 20px/1 dashicons;
                position: absolute;
                right: 0;
                color: $color-gray;
                transition: all $duration;
            }

            &:hover {
                &::before {
                    width: 100%;
                }

                &::after {
                    color: $color-text;
                }
            }

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 15px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;

                &::before {
                    display: none;
                }
            }
        }
    }
    
    // For responsive
    &__button, &__icon, &__close, .submenu__close {
        display: none;
    }

}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main {
        .menu__link {
            max-width: 130px;
            padding: 0 10px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-active-navmain {
        overflow: hidden;
    }    

    .nav-main {
        position: relative;

        // If menu open
        &.js-active-menu {
            
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        // Container menu
        &__container {
            position: fixed;
            top: 0;
            left: -100vw;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 15px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-main;
            padding: 40px 0;
            transition: $duration;
        }
        
        // Burger menu responsive - Open menu
        &__button {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 15px;
            min-height: 69px;
            margin: 0;
            padding: 20px 40px 20px 0;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            font-size: $font-size--5;
            font-weight: $font-weight; 
            color: $color-white;
            text-transform: uppercase;
            transition: $duration;
            cursor: pointer;

            &:hover, &:focus {
                color: $color-white;
            }
        }

        // Button icon (Burger menu responsive - Open menu)
        &__icon {
            position: relative;
            top: -1px;
            display: block;
            width: 20px;
            height: 2px;
            background: $color-white;
            transition: $duration;

            &:before, 
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background: $color-white;
                transition: $duration;
            }

            &:before {
                top: 7px;
            }

            &:after {
                top: -7px;
            } 
        }

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            min-height: 40px;
            margin: 0 40px 0 auto;
            padding: 10px;
            background-color: transparent;
            border: 2px solid $color-white;
            border-radius: $border-radius--round;
            transition: all $duration ease-in-out;

            svg {
                width: 12px;
                height: 12px;
                margin: 0;
                fill: $color-white;
                transition: all $duration ease-in-out;
            }

            &:hover,
            &:focus {
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-dark;
                }
            }
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-y: auto;
            width: 100%;
            padding: 0 40px;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%!important;
                border-bottom: 1px solid rgb(255 255 255 / 30%);
    
                &.menu-item-has-children {

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        color: $color-white;
                        transition: all $duration;
                    }
    
                    &:focus-within .submenu, 
                    &:hover .submenu {
                        justify-content: flex-start;
                    }
    
                    // If sub menu open
                    &.js-active-submenu {   
                        .submenu {
                            right: 0;
                        }
                    }
                }
    
                &:hover, &:focus {
                    background-color: $color-bg--transparent;
    
                    .menu__link {
                        color: $color-white;
                    }
                }
            }

            &__link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: 100%;
                height: auto;
                padding: 20px 0;
                color: $color-white;

                &::after {
                    display: none;
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                    color: $color-white;
                }
            }
        }

        // Sub menu
        .submenu {
            left: inherit;
            top: 0;
            right: 100%;
            z-index: 1;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            width: 100vw;
            height: 100vh;
            padding: 40px 0 60px 0;
            background-color: $color-main;
            transition: $duration;

            &::after {
                display: none;
            }

            // Burger menu responsive - Close submenu
            &__close {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                margin: 0 0 0 40px;
                padding: 0;
                background-color: transparent;
                border: 2px solid $color-white;
                border-radius: $border-radius--round;
                transition: $duration;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        stroke: $color-dark;
                    }
                }

                svg {
                    width: 40px;
                    height: 40px;
                    fill: none;
                    stroke: $color-white;
                    stroke-width: 2px;
                    transition: $duration;
                }
            }

            &__nav {
                display: flex;
                flex-direction: column;
                max-width: 100%;
                padding: 0 40px;
                overflow-y: auto;
            }

            &__item {
                margin: auto; 
            }

            &__link {
                color: $color-white;

                &::after {
                    display: none;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main {
            &__container, .submenu {
                padding-top: 70px;
            }
        }
    }


}
