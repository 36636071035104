.breadcrumb {
    background-color: $color-dark;

    &__container {
        padding: 0;
    }

    ul {
        display: block;
        // padding: 0 0 15px 0;
    }

    li {
        position: relative;
        display: inline;
        color: $color-gray;
        line-height: 1.75;

        a {
            &:hover, &:focus {
                text-decoration: underline;
                color: $color-second;
            }
        }

        svg {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            fill: $color-gray;
            vertical-align: text-top;
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .breadcrumb {
        li svg {
            margin: 0;
        } 

        &__container {
            margin: 0;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        li svg {
            margin: 0 -1px;
        } 
    }

}
